import { request } from "../libs/axios";

const API_ROOT = "https://app.api.photoshareframe.com";

/**
 * It is used to get details of frames by user loggedIn.
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function getFrameDetails() {
  return await request({
    url: `${API_ROOT}/prod/frame`,
    method: "GET",
  });
}

/**
 * It is used to update frame by frame_id.
 * @param frameId
 * @param friendly_name
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function updateFrameByFrameId(frameId, friendly_name) {
  return await request({
    url: `${API_ROOT}/prod/frame?frame_id=${frameId}`,
    body: JSON.stringify(friendly_name),
    method: "PUT",
  });
}

/**
 * It is used to check whether the user is owner or user.
 * @param frameId
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function hasOwnerCheckpoint(frameId) {
  return await request({
    url: `${API_ROOT}/prod/frame/has-owner-checkpoint?frame_id=${frameId}`,
    method: "GET",
  });
}

/**
 * It is used to remove frame by frame_id.
 * @param frameId
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function removeFrame(frameId) {
  return await request({
    url: `${API_ROOT}/prod/frame?frame_id=${frameId}`,
    method: "DELETE",
  });
}

/**
 * It is used to get frame register.
 * @param requestData
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function getFrameRegistration(requestData) {
  return await request({
    url: `${API_ROOT}/prod/frame`,
    body: JSON.stringify(requestData),
    method: "POST",
  });
}

/**
 * It is used to invite users.
 * @param requestData
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function getInviteUsers(requestData) {
  return await request({
    url: `${API_ROOT}/prod/frame/invite`,
    body: JSON.stringify(requestData),
    method: "POST",
  });
}

/**
 * It is used to update users profile.
 * @param requestData
 * @returns {Promise<AxiosResponse<any>|axios.AxiosResponse<any>|*|{message: string, status: boolean}>}
 */
export async function updateProfile(requestData) {
  return await request({
    url: `${API_ROOT}/prod/profile`,
    body: JSON.stringify(requestData),
    method: "PUT",
  });
}
